<template>
  <div id="select-point-on-map"></div>
</template>

<script>
import L from 'leaflet';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import geocoderMixins from '@/mixins/leaflet/geocoderMixins';
import scaleMixins from '@/mixins/leaflet/scaleMixins';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);

export default {
  name: 'SelectPointOnMap',

  mixins: [geocoderMixins, scaleMixins],

  props: {
    zoom: {
      type: Number,
      default: 6
    },
    point: {
      type: Array,
      default: () => []
    },
    initialPosition: {
      type: Object,
      required: false,
    }
  },

  data() {
    return {
      mapaId: 'select-point-on-map',
      map: null,
      layerControl: null,
      popup: null
    };
  },

  mounted() {
    this.drawMap();

    // Atualiza as coordenadas do ponto selecionado
    this.map.on('click', (e) => {
      const { lat, lng } = e.latlng;

      this.pointLocal = [lng, lat];

      this.addPopup(lat, lng);
    });

    // Atualiza o zoom
    this.map.on('zoomend', () => (this.zoomLocal = this.map.getZoom()));
  },

  computed: {
    zoomLocal: {
      get() {
        return this.zoom;
      },
      set(newValue) {
        this.$emit('update:zoom', newValue);
      }
    },

    pointLocal: {
      get() {
        return this.point;
      },
      set(newValue) {
        this.$emit('update:point', newValue);
      }
    }
  },

  methods: {
    drawMap() {
      this.configMap(this.initialPosition?.zoom ?? 3);

      this.map.setView([-13.649319, -56.1236542]);
      this.map.setView([(this.initialPosition?.latitude ?? -13.649319), (this.initialPosition?.longitude ?? -56.1236542)]);

      setTimeout(() => {
        const [lng, lat] = this.point;

        if (!(lat && lng && this.zoom)) return;

        this.map.setView([lat, lng], this.zoom);

        this.addPopup(lat, lng);
      }, 2000);
    },

    configMap(zoom) {
      this.map = L.map(this.mapaId, {
        fullscreenControl: true,
        loadingControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });

      this.layerControl = L.control.layers(mapboxConfig.getBaseLayers());
      this.layerControl.addTo(this.map);

      this.createScaleControl(L).addTo(this.map);
      this.createGeocoderControl(L, this.map).addTo(this.map);
    },

    addPopup(lat, lng) {
      if (this.popup) this.map.removeLayer(this.popup);

      this.popup = L.popup()
        .setLatLng([lat, lng])
        .setContent(
          `
         <strong>Ponto selecionado:</strong><br>
         Latitude: ${lat}<br>
         Longitude: ${lng}
      `
        )
        .openOn(this.map);
    }
  }
};
</script>

<style>
#select-point-on-map {
  height: 400px;
  z-index: 0;
}
</style>
